import React, { useCallback, useMemo, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ReCAPTCHA from "react-google-recaptcha"
import Markdown from "react-markdown"

import {
  Form, FormControl, FormLabel, InputGroup, Container, Row, Col, Button, FormGroup, Alert
} from 'react-bootstrap'
import Header from "../components/header";
import Footer from "../components/footer";

async function sendMail(data) {
  const body = new FormData()
  body.append('data', JSON.stringify(data));
  const resp = await (
    await fetch(`${API_URL}/contacto`, {
      method: 'POST',
      body
    })
  ).json()
  return resp
}

const ContactPage = () => {
  const data = useStaticQuery(query);
  const reCaptchaRef = useRef()
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  const [formData, setFormData] = useState({})

  const currentFormData = useRef()
  currentFormData.current = formData

  const requireFields = useMemo(() => (
    data.strapiContacto.form.filter(v => v.required).map(v => v.name)
  ), [data])

  const onInputChange = useCallback(name => e => {
    setFormData({
      ...currentFormData.current,
      [name]: e.target.value
    })
  }, [setFormData])

  const isFormValid = useMemo(() => (
    requireFields.every(v => formData[v])
  ), [formData, requireFields])

  const onSubmit = useCallback(async e => {
    e.preventDefault()
    setSending(true)
    setError(false)
    try {
      formData._recaptcha = await reCaptchaRef.current.executeAsync()
      const resp = await sendMail(formData)
      if (resp.status === 'OK') {
        setSent(true)
      } else {
        setError(true)
      }
    } catch (err) {
      setError(true)
    }
    setSending(false)
  }, [formData, setSending])

  const seo = {
    metaTitle: data.strapiContacto.title,
    metaDescription: data.strapiContacto.description,
    shareImage: {
      publicURL: data.strapiContacto.image.childImageSharp.fluid.src
    }
  };

  return (
    <Layout seo={seo}>
      <Header
        image={data.strapiContacto.image.childImageSharp.fluid}
        title={data.strapiContacto.title}
        description={data.strapiContacto.description}
      />
      <section className="mr-page-section">
        <Container>
          <Row>
            <Col>
              <Markdown source={data.strapiContacto.content} escapeHtml={false} />
            </Col>
          </Row>
          <Row>
            <Col>
            <Form onSubmit={onSubmit} className="mr-form margin-t">
              <Row>

            {
              data.strapiContacto.form.map((item, idx) => (
                <Col key={idx} md={item.type === 'textarea' ? 12 : 6}>
                  <FormGroup>
                      <FormLabel className="mr-label">{item.label}</FormLabel>
                      <FormControl
                        as={item.type === 'textarea' ? 'textarea' : 'input'}
                        type={item.type}
                        name={item.name}
                        required={item.required}
                        onChange={onInputChange(item.name)}
                      />
                  </FormGroup>
                </Col>
              ))
            }
              </Row>
            {
              error ? (
                <Row>
                  <Col>
                    <Alert variant="danger">
                      {data.strapiContacto.errorMessage}
                    </Alert>
                  </Col>
                </Row>
              ) : null
            }
            {
              sent ? (
                <Row>
                  <Col>
                    <Alert variant="success">
                      {data.strapiContacto.confirmMessage}
                    </Alert>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <Button type="submit" disabled={sending || !isFormValid}>{
                      sending ? 'Enviando...' : 'Enviar'
                    }</Button>
                  </Col>
                </Row>
              )
            }
          <ReCAPTCHA
            size="invisible"
            sitekey={data.strapiGlobal.reCaptcha.publicKey}
            theme="light"
            ref={reCaptchaRef}
          />
          </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiContacto {
      confirmMessage
      errorMessage
      content
      title
      description
      image {
        childImageSharp {
          fluid (fit: COVER, sizes: "576px, 992px, 1680px", srcSetBreakpoints: [576, 992, 1680]) {
            srcSet
            srcSetWebp
            src
          }
        }
      }
      targetEmail
      form {
        label
        name
        required
        type
      }
    }
    strapiGlobal {
      reCaptcha {
        publicKey
      }
    }
  }
`;

export default ContactPage;
